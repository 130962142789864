import React from "react"

import DocumentLayout from "../../components/layout/document"

// import ChatIcon from "../../../static/svgs/chat.svg"

export default () => {
  // const Item = ({ name, icon, link }) => {
  //   return (
  //     <div className="col-md-3 text-center py-2">
  //       <a href={link} target="_blank" className="nounderline">
  //         <div className="box box-primary">
  //           <img src={icon} height="60px" />
  //           <h4 className="mt-3">{name}</h4>
  //         </div>
  //       </a>
  //     </div>
  //   )
  // }

  return (
    <DocumentLayout title="RESTful Libraries">
      <div className="headline">
        <h1 className="text-primary m-0">RESTful Libraries</h1>
      </div>
      <p className="text-muted">
        unirest is a set of lightweight HTTP libraries available in multiple
        language.
      </p>
      <div className="row">
        {/* <Item
          name="NodeJS"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        />
        <Item
          name="Ruby"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        />
        <Item
          name="PHP"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        />
        <Item
          name="JAVA"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        />
        <Item
          name="Python"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        />
        <Item
          name=".NET"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        />
        <Item
          name="Objective-C"
          icon={ChatIcon}
          link="http://unirest.io/nodejs.html"
        /> */}
      </div>
    </DocumentLayout>
  )
}
